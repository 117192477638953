import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />

     {/* Page Header Start */}
     <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <h1 className="display-1 text-white animated slideInDown">
            Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* Service Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h4 className="section-title">Our Services</h4>
            <h1 className="display-5 mb-4">
              We Focused On Modern Architecture And Interior Design
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-1.jpg" alt="" />
                <div className="service-text p-5">
                  <img
                    className="mb-4"
                    src="img/icons/icon1.png"
                    alt="Icon"
                    style={{ width: "100px" }}
                  />
                  <h3 className="mb-3">Sea Freight</h3>
                  <p className="mb-4">
                  We serve as the gateway to your sales and procurement market, providing innovative solutions that connect your shipments to their destinations.
                  </p>
                  <Link className="btn" to="/Sea">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-2.jpg" alt="" />
                <div className="service-text p-5">
                  <img
                    className="mb-4"
                    src="img/icons/icon2.png"
                    alt="Icon"
                    style={{ width: "100px" }}
                  />
                  <h3 className="mb-3">Air Freight</h3>
                  <p className="mb-4">
                  Our specialization in air freight solutions, coupled with our team of dedicated account managers and round-the-clock service assurance,...
                  </p>
                  <Link className="btn" to="/Air">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-3.jpg" alt="" />
                <div className="service-text p-5">
                  <img className="mb-4" src="img/icons/icon3.png" alt="Icon" />
                  <h3 className="mb-3">Ground Transportation</h3>
                  <p className="mb-4">
                  Our land transport services are designed to meet the specific requirements of our customers, ensuring precise alignment with their needs....
                  </p>
                  <Link className="btn" to="/Ground">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-4.jpg" alt="" />
                <div className="service-text p-5">
                  <img className="mb-4" src="img/icons/icon4.png" alt="Icon" />
                  <h3 className="mb-3">Warehousing & Distribution</h3>
                  <p className="mb-4">
                  Our company offers comprehensive warehousing network solutions, supported by well-trained manpower and excellent managerial services...
                  </p>
                  <Link className="btn" to="/Warehousing">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-5.jpg" alt="" />
                <div className="service-text p-5">
                  <img className="mb-4" src="img/icons/icon5.png" alt="Icon" />
                  <h3 className="mb-3">Projects & Break Bulk</h3>
                  <p className="mb-4">
                  Our expertise lies in handling project cargo that requires special care, attention, and expertise. We serve a wide range of industries, ..
                  </p>
                  <Link className="btn" to="/Projects">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item d-flex position-relative text-center h-100">
                <img className="bg-img" src="img/service-6.jpg" alt="" />
                <div className="service-text p-5">
                  <img className="mb-4" src="img/icons/icon6.png" alt="Icon" />
                  <h3 className="mb-3">Hazardous Cargo</h3>
                  <p className="mb-4">
                  Verve International specializes in providing comprehensive logistics solutions for dangerous and hazardous cargo. Our business is built...
                  </p>
                  <Link className="btn" to="/Hazardous">
                    <i className="fa fa-plus text-primary me-3" />
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}

      <Footer />
    </>
  );
}
