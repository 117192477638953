import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Ocean() {
  return (
    <>
      <Header />

      <section className="section db p120">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tagline-message page-title text-center">
                <h3>Project cargo</h3>
                <ul className="breadcrumb">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">Project cargo</li>
                </ul>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      <section className="section gb nopadtop">
        <div className="container">
          <div className="boxed">
            <div className="row">
              <div className="col-md-8">
                <div className="content blog-list">
                  <div className="blog-wrapper clearfix">
                    <div className="blog-meta">
                      <small>Services</small>
                      <h3 className="text-center">Project cargo </h3>
                    </div>
                    {/* end blog-meta */}
                    <div className="blog-media">
                      <img
                        src="images/project.webp"
                        alt=""
                        className="img-responsive img-rounded"
                      />
                    </div>
                    {/* end media */}
                    <div className="blog-desc-big"> 
                      <p>
                        In order to cater to our clients' international
                        projects, we have adapted our operations to meet their
                        specific requirements on demand. Our experienced team is
                        capable of handling projects of all sizes, ensuring that
                        we allocate the right personnel based on client needs.
                        <br />
                        <br />
                        Through our contracts with heavylift operators, charter
                        companies, and manpower suppliers, we have established
                        reliable partnerships that allow us to secure necessary
                        equipment, aircraft, vessels, and manpower at short
                        notice. This enables us to remain agile and focus on
                        budgeting, routing, and project execution without the
                        burden of owning these heavy assets. Consequently, we
                        can provide our clients with the best and most
                        cost-effective quotes.
                        <br />
                        <br />
                        By entrusting us with your project, you can be confident
                        that our dedicated team will meet your project
                        deadlines. We offer comprehensive Project Forwarding
                        services, including the placement of a dedicated project
                        team in-house and/or on-site as required. We also
                        benefit from the full support of our worldwide agents,
                        who are experienced in handling all aspects of the
                        project. Additionally, we optimize vendor consolidation
                        through key hubs, facilitating swift executions even on
                        short notice.
                        <br />
                      </p>
                    </div>
                    {/* end desc */}
                  </div>
                  {/* end blog */}
                </div>
                {/* end content */}
              </div>
              {/* end col */}
              <div className="sidebar col-md-4">
                <div className="widget clearfix">
                  <h3 className="widget-title">Our Services</h3>

                  {/* end post-widget */}
                </div>
                {/* end widget */}

                <div className="media">
                  <img
                    src="images/ocean.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/air.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Air">Air Freight</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/warehousing.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Warehousing">Warehousing</Link>
                    </h3>
                  </div>
                </div>

                <div className="media">
                  <img
                    src="images/logistics.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Logistics">Logistics Solutions</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/project.webp"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Project">Project Cargo</Link>
                    </h3>
                  </div>
                </div>
              </div>
              {/* end sidebar */}
            </div>
            {/* end row */}
          </div>
          {/* end boxed */}
        </div>
        {/* end container */}
      </section>
      <Footer />
    </>
  );
}
