import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Ocean() {
  return (
    <>
      <Header />

      <section className="section db p120">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tagline-message page-title text-center">
                <h3>Logistics Solutions</h3>
                <ul className="breadcrumb">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">Logistics Solutions</li>
                </ul>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      <section className="section gb nopadtop">
        <div className="container">
          <div className="boxed">
            <div className="row">
              <div className="col-md-8">
                <div className="content blog-list">
                  <div className="blog-wrapper clearfix">
                    <div className="blog-meta">
                      <small>Services</small>
                      <h3 className="text-center">Logistics Solutions </h3>
                    </div>
                    {/* end blog-meta */}
                    <div className="blog-media">
                      <img
                        src="images/logistics.jpg"
                        alt=""
                        className="img-responsive img-rounded"
                      />
                    </div>
                    {/* end media */}
                    <div className="blog-desc-big">
                      <p>
                        In order to meet the evolving needs of our clients,{" "}
                        {companyname} is committed to modernizing its warehouse
                        and distribution facilities. We strive to enhance
                        Warehouse Management, Inventory Control, and Order
                        Fulfillment capabilities to deliver improved services.
                        <br />
                        <br />
                        As business requirements change, the demand for higher
                        efficiency and cost-effective logistics solutions has
                        become more pronounced. At {companyname}, we are
                        equipped to address any shipping requirement and serve
                        as a single-source logistics provider for all products
                        or components within your supply chain.
                        <br />
                        <br />
                        Our Door To Door (DTD) program offers comprehensive
                        logistics solutions. Under this program, we handle the
                        entire logistics process, starting from picking up
                        shipments from the origin point and ensuring their safe
                        delivery to the designated door in India.
                        <br />
                        <br />
                        To ensure smooth coordination throughout the supply
                        chain, we provide an Account Coordinator who acts as a
                        central point of contact. They closely monitor shipments
                        from origin to destination, as well as the customs
                        clearance process. This eliminates communication gaps
                        when interacting with multiple agencies involved in the
                        supply chain. Additionally, periodic reports are
                        generated and shared with all relevant personnel to keep
                        them informed of the shipment status.
                        <br />
                      </p>
                    </div>
                    {/* end desc */}
                  </div>
                  {/* end blog */}
                </div>
                {/* end content */}
              </div>
              {/* end col */}
              <div className="sidebar col-md-4">
                <div className="widget clearfix">
                  <h3 className="widget-title">Our Services</h3>

                  {/* end post-widget */}
                </div>
                {/* end widget */}

                <div className="media">
                  <img
                    src="images/ocean.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/air.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Air">Air Freight</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/warehousing.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Warehousing">Warehousing</Link>
                    </h3>
                  </div>
                </div>

                <div className="media">
                  <img
                    src="images/logistics.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Logistics">Logistics Solutions</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/project.webp"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Project">Project Cargo</Link>
                    </h3>
                  </div>
                </div>
              </div>
              {/* end sidebar */}
            </div>
            {/* end row */}
          </div>
          {/* end boxed */}
        </div>
        {/* end container */}
      </section>
      <Footer />
    </>
  );
}
