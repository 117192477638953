import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Warehousing() {
  return (
    <>
      <Header />

      <section className="section db p120">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tagline-message page-title text-center">
                <h3>About Us</h3>
                <ul className="breadcrumb">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">About Us</li>
                </ul>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 hidden-sm hidden-xs">
              <div className="custom-module">
                <img
                  src="images/about.png"
                  alt=""
                  className="img-responsive wow slideInLeft"
                />
              </div>
              {/* end module */}
            </div>
            {/* end col */}
            <div className="col-md-8">
              <div className="custom-module p40l">
                <h2>
                  Welcome to <mark>{companyname}</mark>
                </h2>
                <p>
                  At our company, we take pride in offering our customers a
                  hassle-free and diverse range of services to meet their
                  specific requirements. These services encompass airfreight,
                  seafreight, warehousing, customs clearance, and transport,
                  along with valuable additional services like Cargo Insurance,
                  packing, fumigation, palletization, and more, as and when
                  needed.
                  <br />
                  <br />
                  Our commitment to providing authentic and reliable services
                  has earned us an unrivaled reputation among our peers in the
                  industry. We are known for delivering trustworthy solutions
                  that cater to the unique needs of our clients.
                  <br />
                  <br />
                  Operating across all sectors of the industry, we excel at
                  tailoring our services to suit individual demands. This
                  flexibility allows us to provide reliable personalized
                  service, fostering strong relationships with our clients. Our
                  ability to adapt and cater to their specific requirements has
                  been key to our continuous growth and success.
                  <br />
                  <br />
                  As an independent air and sea cargo operator, we have
                  strategically positioned ourselves among the most esteemed
                  providers of freight forwarding services in India. With a
                  focus on customer satisfaction and a commitment to excellence,
                  we continue to enhance our standing in the industry as a
                  trusted and preferred partner for all freight-related needs.
                </p>

                <hr className="invis" />
              </div>
              {/* end module */}
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      <section className="section db p120">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tagline-message text-center"></div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      <section className="section gb nopadtop">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="box m30">
                <i class="fa fa-bullseye" aria-hidden="true"></i>
                <h4>Our Mission</h4>
                <p>
                  Our goal is clear - to be the leading and most highly regarded
                  client-centric freight forwarder, dedicated to providing
                  unparalleled services in our pursuit of excellence. We are
                  committed to delivering exceptional solutions that exceed the
                  expectations of our valued clients. Through our unwavering
                  focus on customer satisfaction and continuous improvement, we
                  aim to set new standards of excellence in the freight
                  forwarding industry.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box m30">
                <i class="fa fa-eye" aria-hidden="true"></i>

                <h4>Our Vision</h4>
                <p>
                  Our core objectives are centered on delivering exceptional
                  freight services to global destinations for our clients. We
                  strive to achieve significant business growth by consistently
                  providing quality service and enhancing the efficiency of the
                  supply chain. Building and nurturing strong business
                  relationships with our clients and partners is vital as we
                  continuously pursue excellence in all aspects of our
                  operations.
                </p>
              </div>
            </div>
          </div>
          {/* end row */}
          <hr className="invis" />
        </div>
        {/* end container */}
      </section>
      <Footer />
    </>
  );
}
