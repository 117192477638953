import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <section
        id="home"
        className="video-section js-height-full w3-banner jarallax"
      >
        <div
          id="home"
          className="video-section js-height-full w3-banner jarallax"
          style={{ background: "#00000021" }}
        >
          <video
            className="img-fluid"
            autoPlay
            muted
            loop
            style={{
              zIndex: -1,
              height: "600px",
              position: "relative",
            }}
          >
            <source src="images/video.mp4" type="video/mp4" />
          </video>

          <div
            className="home-text-wrapper relative container "
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              zIndex: -1,
              position: "absolute",
              color: "#fff" /* margin: '20rem 10rem', */,
              padding: "5rem" /* backgroundColor: '#000', */,
              alignSelf: "flex-end",
              borderRadius: "2rem",
            }}
          >
            <div className="home-message">
              <p>We are {companyname}</p>
              <small>
                At our core, we believe that trade has the power to transform
                businesses and individuals alike. By offering comprehensive and
                efficient freight forwarding solutions, we empower our clients
                to navigate global markets with confidence and ease.
              </small>
              <div className="btn-wrapper">
                <div className="text-center">
                  <Link to="/About" className="btn btn-primary wow slideInLeft">
                    Read More
                  </Link>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <Link
                    to="/Contact"
                    className="btn btn-primary wow slideInRight"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
              {/* end row */}
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-4 hidden-sm hidden-xs">
              <div className="custom-module">
                <img
                  src="images/about.png"
                  alt=""
                  className="img-responsive wow slideInLeft"
                />
              </div>
              {/* end module */}
            </div>
            {/* end col */}
            <div className="col-md-8">
              <div className="custom-module p40l">
                <h2>
                  Welcome to <mark>{companyname}</mark>
                </h2>
                <p>
                  At our company, we take pride in offering our customers a
                  hassle-free and diverse range of services to meet their
                  specific requirements. These services encompass airfreight,
                  seafreight, warehousing, customs clearance, and transport,
                  along with valuable additional services like Cargo Insurance,
                  packing, fumigation, palletization, and more, as and when
                  needed.
                  <br />
                  <br />
                  Our commitment to providing authentic and reliable services
                  has earned us an unrivaled reputation among our peers in the
                  industry. We are known for delivering trustworthy solutions
                  that cater to the unique needs of our clients.
                  <br />
                  <br />
                  Operating across all sectors of the industry, we excel at
                  tailoring our services to suit individual demands. This
                  flexibility allows us to provide reliable personalized
                  service, fostering strong relationships with our clients. Our
                  ability to adapt and cater to their specific requirements has
                  been key to our continuous growth and success.
                  <br />
                </p>

                <hr className="invis" />
                <div className="btn-wrapper">
                  <Link to="/About" className="btn btn-primary">
                    Learn More About us
                  </Link>
                </div>
              </div>
              {/* end module */}
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>

      <section className="section db p120">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tagline-message text-center"></div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      <section className="section gb nopadtop">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="box m30">
                <i class="fa fa-bullseye" aria-hidden="true"></i>
                <h4>Our Mission</h4>
                <p>
                  Our goal is clear - to be the leading and most highly regarded
                  client-centric freight forwarder, dedicated to providing
                  unparalleled services in our pursuit of excellence. We are
                  committed to delivering exceptional solutions that exceed the
                  expectations of our valued clients. Through our unwavering
                  focus on customer satisfaction and continuous improvement, we
                  aim to set new standards of excellence in the freight
                  forwarding industry.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box m30">
                <i class="fa fa-eye" aria-hidden="true"></i>

                <h4>Our Vision</h4>
                <p>
                  Our core objectives are centered on delivering exceptional
                  freight services to global destinations for our clients. We
                  strive to achieve significant business growth by consistently
                  providing quality service and enhancing the efficiency of the
                  supply chain. Building and nurturing strong business
                  relationships with our clients and partners is vital as we
                  continuously pursue excellence in all aspects of our
                  operations.
                </p>
              </div>
            </div>
          </div>
          {/* end row */}
          <hr className="invis" />
        </div>
        {/* end container */}
      </section>

      <section className="section gb">
        <div className="container">
          <div className="section-title text-center">
            <h3>Services We Provide..</h3>
            <p>
              Maecenas sit amet tristique turpis. Quisque porttitor eros quis
              leo pulvinar, at hendrerit sapien iaculis. Donec consectetur
              accumsan arcu, sit amet fringilla ex ultricies.
            </p>
          </div>
          {/* end title */}
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <div className="blog-box">
                <div className="image-wrap entry">
                  <img
                    src="images/ocean.jpg"
                    alt=""
                    className="img-responsive"
                    style={{ height: "240px" }}
                  />
                  <div className="magnifier">
                    <Link to="/Ocean" title>
                      <i className="flaticon-add" />
                    </Link>
                  </div>
                </div>
                {/* end image-wrap */}
                <div className="blog-desc">
                  <h4>
                    <Link to="/Ocean">Ocean Freight</Link>
                  </h4>
                  <p>
                    We specialize in providing a comprehensive range of sea
                    freight services without any limitations on the size or
                    weight of the goods. As a highly efficient international
                    ocean freight forwarder, we have the expertise to offer
                    extensive services.
                  </p>
                </div>
                {/* end blog-desc */}
              </div>
              {/* end blog */}
            </div>
            {/* end col */}

            <div className="col-lg-4 col-md-12">
              <div className="blog-box">
                <div className="image-wrap entry">
                  <img
                    src="images/air.jpg"
                    alt=""
                    className="img-responsive"
                    style={{ height: "240px" }}
                  />
                  <div className="magnifier">
                    <Link to="/Air" title>
                      <i className="flaticon-add" />
                    </Link>
                  </div>
                </div>
                {/* end image-wrap */}
                <div className="blog-desc">
                  <h4>
                    <Link to="/Air">Air Freight</Link>
                  </h4>
                  <p>
                  Our clients benefit from a wide selection of competitive
                        rates across multiple airlines and sectors, thanks to
                        our agreements with all major airlines. This grants our
                        clients the flexibility to choose from a diverse range
                        of services.
                  </p>
                </div>
                {/* end blog-desc */}
              </div>
              {/* end blog */}
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="blog-box">
                <div className="image-wrap entry">
                  <img
                    src="images/warehousing.jpg"
                    alt=""
                    className="img-responsive"
                    style={{ height: "240px" }}
                  />
                  <div className="magnifier">
                    <Link to="/Warehousing" title>
                      <i className="flaticon-add" />
                    </Link>
                  </div>
                </div>
                {/* end image-wrap */}
                <div className="blog-desc">
                  <h4>
                    <Link to="/Warehousing">Warehousing</Link>
                  </h4>
                  <p>
                  As part of our comprehensive range of services,{" "}
                        {companyname} has established warehousing partnerships
                        with service providers in strategic locations throughout
                        the country.
                        <br />
                        <br />
                  </p>
                </div>
                {/* end blog-desc */}
              </div>
              {/* end blog */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2 col-md-12"></div>
            <div className="col-lg-4 col-md-12">
              <div className="blog-box">
                <div className="image-wrap entry">
                  <img
                    src="images/logistics.jpg"
                    alt=""
                    className="img-responsive"
                    style={{ height: "240px" }}
                  />
                  <div className="magnifier">
                    <Link to="/Logistics" title>
                      <i className="flaticon-add" />
                    </Link>
                  </div>
                </div>
                {/* end image-wrap */}
                <div className="blog-desc">
                  <h4>
                    <Link to="/Logistics">Logistics Solutions</Link>
                  </h4>
                  <p>
                  In order to meet the evolving needs of our clients,{" "}
                        {companyname} is committed to modernizing its warehouse
                        and distribution facilities. We strive to enhance
                        Warehouse Management, Inventory Control, and Order
                        Fulfillment capabilities to deliver improved services.
                  </p>
                </div>
                {/* end blog-desc */}
              </div>
              {/* end blog */}
            </div>
            {/* end col */}

            <div className="col-lg-4 col-md-12">
              <div className="blog-box">
                <div className="image-wrap entry">
                  <img
                    src="images/project.webp"
                    alt=""
                    className="img-responsive"
                    style={{ height: "240px" }}
                  />
                  <div className="magnifier">
                    <Link to="/Project" title>
                      <i className="flaticon-add" />
                    </Link>
                  </div>
                </div>
                {/* end image-wrap */}
                <div className="blog-desc">
                  <h4>
                    <Link to="/Project">Project Cargo</Link>
                  </h4>
                  <p>
                  In order to cater to our clients' international
                        projects, we have adapted our operations to meet their
                        specific requirements on demand. Our experienced team is
                        capable of handling projects of all sizes, ensuring that
                        we allocate the right personnel based on client needs.
                       
                  </p>
                </div>
                {/* end blog-desc */}
              </div>
              {/* end blog */}
            </div>
            <div className="col-lg-2 col-md-12"></div>
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>

      <Footer />
    </>
  );
}
