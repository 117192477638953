import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
     
      
     <header className="header" style={{padding:'20px', position: 'fixed', background:'white'}}>
     
        <div className="container">
          <nav className="navbar navbar-default yamm">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <div className="" style={{
    position: 'fixed',
    top: '6px'
}}>
                <Link className="navbar-brand" to="index.html"><img src="images/logo.png" style={{width:'150px'}} alt="" /></Link>
              </div>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              <ul className="nav navbar-nav navbar-right">
                <li><Link to="/Home">Home</Link></li>
                
                <li><Link to="/About">About Us</Link></li>
               
                <li className="dropdown hassubmenu">
                  <Link to="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">Services <span className="fa fa-angle-down" /></Link>
                  <ul className="dropdown-menu" role="menu">
                    <li><Link to="/Ocean">Ocean Freight</Link></li>
                    <li><Link to="/Air">Air Freight</Link></li>
                    <li><Link to="/Warehousing">Warehousing</Link></li>
                    <li><Link to="/Logistics">Logistics Solutions</Link></li>
                    <li><Link to="/Project">Project cargo</Link></li>
                   

                    
                  </ul>
                </li>
                <li><Link to="/Contact">Contact</Link></li>
                
              </ul>
            </div>
          </nav>{/* end navbar */}
        </div>{/* end container */}
      </header>
      

      <Outlet />
    </>
  );
};

export default Header;
