import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />

      <section className="section db p120">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tagline-message page-title text-center">
                <h3>Air Freight</h3>
                <ul className="breadcrumb">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">Air Freight</li>
                </ul>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      <section className="section gb nopadtop">
        <div className="container">
          <div className="boxed">
            <div className="row">
              <div className="col-md-8">
                <div className="content blog-list">
                  <div className="blog-wrapper clearfix">
                    <div className="blog-meta">
                      <small>Services</small>
                      <h3 className="text-center">Air Freight </h3>
                    </div>
                    {/* end blog-meta */}
                    <div className="blog-media">
                      <img
                        src="images/air.jpg"
                        alt=""
                        className="img-responsive img-rounded"
                      />
                    </div>
                    {/* end media */}
                    <div className="blog-desc-big">
                      <p>
                        With an extensive network of operators at our disposal,
                        we ensure timely and cost-effective delivery of goods to
                        any desired destination.
                        <br />
                        <br />
                        Our clients benefit from a wide selection of competitive
                        rates across multiple airlines and sectors, thanks to
                        our agreements with all major airlines. This grants our
                        clients the flexibility to choose from a diverse range
                        of services.
                        <br />
                        <br />
                        Through our global network of agents, we provide
                        door-to-door service and leverage their base airports
                        for efficient cargo transshipment.
                        <br />
                        <br />
                        In cases where services from the base ports are limited
                        or unavailable, we proactively explore new trade routes
                        on behalf of our clients. This allows them to enhance
                        their supply chain management, expand their horizons,
                        and increase overall efficiency.
                        <br />
                        <br />
                        We possess expertise in handling various types of
                        shipments, including hazardous and non-hazardous
                        materials, as well as temperature-controlled goods. Our
                        well-qualified staff members are well-versed in every
                        aspect of managing hazardous shipments, including those
                        classified as radioactive.
                      </p>
                    </div>
                    {/* end desc */}
                  </div>
                  {/* end blog */}
                </div>
                {/* end content */}
              </div>
              {/* end col */}
              <div className="sidebar col-md-4">
                <div className="widget clearfix">
                  <h3 className="widget-title">Our Services</h3>

                  {/* end post-widget */}
                </div>
                {/* end widget */}

                <div className="media">
                  <img
                    src="images/ocean.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/air.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Air">Air Freight</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/warehousing.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Warehousing">Warehousing</Link>
                    </h3>
                  </div>
                </div>

                <div className="media">
                  <img
                    src="images/logistics.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Logistics">Logistics Solutions</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/project.webp"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Project">Project Cargo</Link>
                    </h3>
                  </div>
                </div>
              </div>
              {/* end sidebar */}
            </div>
            {/* end row */}
          </div>
          {/* end boxed */}
        </div>
        {/* end container */}
      </section>
      <Footer />
    </>
  );
}
