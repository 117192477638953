import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Ocean() {
  return (
    <>
      <Header />

      <section className="section db p120">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tagline-message page-title text-center">
                <h3>Ocean Freight</h3>
                <ul className="breadcrumb">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">Ocean Freight</li>
                </ul>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      <section className="section gb nopadtop">
        <div className="container">
          <div className="boxed">
            <div className="row">
              <div className="col-md-8">
                <div className="content blog-list">
                  <div className="blog-wrapper clearfix">
                    <div className="blog-meta">
                      <small>Services</small>
                      <h3 className="text-center">Ocean Freight </h3>
                    </div>
                    {/* end blog-meta */}
                    <div className="blog-media">
                      <img
                        src="images/ocean.jpg"
                        alt=""
                        className="img-responsive img-rounded"
                      />
                    </div>
                    {/* end media */}
                    <div className="blog-desc-big">
                      <p>
                        We specialize in providing a comprehensive range of sea
                        freight services without any limitations on the size or
                        weight of the goods. As a highly efficient international
                        ocean freight forwarder, we have the expertise to offer
                        extensive services to nearly all seaport locations.
                        <br />
                        <br />
                        Our knowledgeable team and network of associates are
                        well-trained to navigate the complexities of
                        international shipping. We customize programs to meet
                        the specific requirements of our clients, whether they
                        need full container load (FCL), less than container load
                        (LCL), or Over Dimensional Cargo services, such as
                        Flatrack containers or Open top containers.
                        <br />
                        <br />
                        Due to the significant volume of business we handle, we
                        can negotiate competitive rates with various Liners and
                        NVOCCs, providing rate protection even during peak
                        seasons. Our strong relationships with these shipping
                        companies also allow us to offer flexible sailing
                        schedules for select sectors.
                        <br />
                        <br />
                        Moreover, we understand the importance of a seamless
                        door-to-door service, and we offer it whenever our
                        clients require it. With our own Customs license, we
                        have the capability to handle in-house customs clearance
                        for both inbound and outbound shipments.
                        <br />
                      </p>
                    </div>
                    {/* end desc */}
                  </div>
                  {/* end blog */}
                </div>
                {/* end content */}
              </div>
              {/* end col */}
              <div className="sidebar col-md-4">
                <div className="widget clearfix">
                  <h3 className="widget-title">Our Services</h3>

                  {/* end post-widget */}
                </div>
                {/* end widget */}

                <div className="media">
                  <img
                    src="images/ocean.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/air.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Air">Air Freight</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/warehousing.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Warehousing">Warehousing</Link>
                    </h3>
                  </div>
                </div>

                <div className="media">
                  <img
                    src="images/logistics.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Logistics">Logistics Solutions</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/project.webp"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Project">Project Cargo</Link>
                    </h3>
                  </div>
                </div>
                
              </div>
              {/* end sidebar */}
            </div>
            {/* end row */}
          </div>
          {/* end boxed */}
        </div>
        {/* end container */}
      </section>
      <Footer />
    </>
  );
}
