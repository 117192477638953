import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
      <footer className="section footer noover">
          <div className="container">
            <div className="row">
             
              <div className="col-lg-4 col-md-3">
                <div className="widget clearfix">
                  <h3 className="widget-title">{companyname}</h3>
                  <p>  At our company, we take pride in offering our customers a
                  hassle-free and diverse range of services to meet their
                  specific requirements.</p>
                  <Link to="/About" className="readmore">Learn More</Link>
                </div>{/* end widget */}
              </div>{/* end col */}
              <div className="col-lg-2 col-md-2">
                <div className="widget clearfix">
                  <h3 className="widget-title">Important Links</h3>
                  <div className="list-widget">   
                    <ul>
                    <li><Link to="/Home">Home</Link></li>
                    <li><Link to="/About">About</Link></li>
                    <li><Link to="/Services">Services</Link></li>
                    <li><Link to="/Contact">Contact</Link></li>


                    </ul>
                  </div>{/* end list-widget */}
                </div>{/* end widget */}
              </div>{/* end col */}
              <div className="col-lg-2 col-md-2">
                <div className="widget clearfix">
                  <h3 className="widget-title">Our Services</h3>
                  <div className="list-widget">   
                    <ul>
                    <li><Link to="/Ocean">Ocean Freight</Link></li>
                    <li><Link to="/Air">Air Freight</Link></li>
                    <li><Link to="/Warehousing">Warehousing</Link></li>
                    <li><Link to="/Logistics">Logistics Solutions</Link></li>
                    <li><Link to="/Project">Project cargo</Link></li>
                    </ul>
                  </div>{/* end list-widget */}
                </div>{/* end widget */}
              </div>{/* end col */}
              <div className="col-lg-4 col-md-3">
                <div className="widget clearfix text-center">
                  <h3 className="widget-title ">Contact Us</h3>
                  <p className=" "> Address:{companyaddress} <br/>
                  Phone: {companynumber}<br/>
                  Email: {companyemail}
                   </p>
                  <Link to="/Contact" className="readmore">Contact</Link>
                </div>{/* end widget */}
              </div>{/* end col */}
            </div>{/* end row */}
          </div>{/* end container */}
        </footer>{/* end footer */}
        <div className="copyrights">
          <div className="container">
            <div className="clearfix">
              <div className="pull-left">
                <div className="cop-logo">
                  <Link to="#"><img src="images/logo.png" alt="" style={{width:'150px'}}  /></Link>
                </div>
              </div>
              <div className="pull-right">
                <div className="footer-links">
                  <ul className="list-inline">
                    <li> <Link to="/Privacy">Privacy Policy</Link></li>
                    <li> <Link to="/Terms">Terms & Conditions</Link></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>{/* end container */}
        </div>{/* end copy */}
        {/* end wrapper */}

      <Outlet />
    </>
  );
};

export default Footer;
