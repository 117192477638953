import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Warehousing() {
  return (
    <>
      <Header />

      <section className="section db p120">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="tagline-message page-title text-center">
                <h3>Warehousing</h3>
                <ul className="breadcrumb">
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li className="active">Warehousing</li>
                </ul>
              </div>
            </div>
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end section */}
      <section className="section gb nopadtop">
        <div className="container">
          <div className="boxed">
            <div className="row">
              <div className="col-md-8">
                <div className="content blog-list">
                  <div className="blog-wrapper clearfix">
                    <div className="blog-meta">
                      <small>Services</small>
                      <h3 className="text-center">Warehousing </h3>
                    </div>
                    {/* end blog-meta */}
                    <div className="blog-media">
                      <img
                        src="images/warehousing.jpg"
                        alt=""
                        className="img-responsive img-rounded"
                      />
                    </div>
                    {/* end media */}
                    <div className="blog-desc-big">
                      <p>
                        As part of our comprehensive range of services,{" "}
                        {companyname} has established warehousing partnerships
                        with service providers in strategic locations throughout
                        the country.
                        <br />
                        <br />
                        We have a dedicated transit warehouse that plays a
                        crucial role in handling shipments intended for air
                        export or import. This facility allows for labeling,
                        repacking, and other necessary processes to be carried
                        out, if required. Additionally, the transit warehouse
                        serves as a temporary storage station where cargo is
                        consolidated before its onward journey.
                        <br />
                        <br />
                        Our Temperature-controlled Warehouse is certified under
                        Good Distribution Practices (GDP), ensuring the highest
                        standards for the storage and handling of
                        temperature-sensitive goods.
                        <br />
                        <br />
                        To maintain efficient operations, we have implemented a
                        state-of-the-art Warehouse Management System. This
                        system facilitates the orderly storage of goods,
                        minimizing unnecessary handling and streamlining
                        processes.
                        <br />
                        <br />
                        We prioritize safety and security in our warehouse
                        operations, strictly adhering to the regulations and
                        procedures established by the Civil Defence Departments.
                        Our warehouse staff members are well-trained on safety
                        procedures and operate equipment in compliance with the
                        rules of warehouse management.
                      </p>
                    </div>
                    {/* end desc */}
                  </div>
                  {/* end blog */}
                </div>
                {/* end content */}
              </div>
              {/* end col */}
              <div className="sidebar col-md-4">
                <div className="widget clearfix">
                  <h3 className="widget-title">Our Services</h3>

                  {/* end post-widget */}
                </div>
                {/* end widget */}

                <div className="media">
                  <img
                    src="images/ocean.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Ocean">Ocean Freight</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/air.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Air">Air Freight</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/warehousing.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Warehousing">Warehousing</Link>
                    </h3>
                  </div>
                </div>

                <div className="media">
                  <img
                    src="images/logistics.jpg"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Logistics">Logistics Solutions</Link>
                    </h3>
                  </div>
                </div>
                <div className="media">
                  <img
                    src="images/project.webp"
                    alt=""
                    className="img-responsive alignleft img-rounded"
                    style={{ width: "80px" }}
                  />
                  <div className="media-body align-items-center">
                    <h3 className="pb-4">
                      <Link to="/Project">Project Cargo</Link>
                    </h3>
                  </div>
                </div>
                
              </div>
              {/* end sidebar */}
            </div>
            {/* end row */}
          </div>
          {/* end boxed */}
        </div>
        {/* end container */}
      </section>
      <Footer />
    </>
  );
}
